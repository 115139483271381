export const config = {
    saleAddress: "0x54dBa685CC654B225fD2b79359D5152007c7d763",
    price: 300,
    stakingAddress: "0x26D3aBFBC8f86Bde1234d446Cb669CBBfEB632fC",
    swapAddress: "0x4aCAD9b5aA8BDfD43F2BF11fAc04Ed285068dbAE",
    InfuraUrl: "https://rpc.pulsechain.com/",
    defaultReferral: "0x110b2311b4e2548F1B98057536e44e88b92CfEE9",
    tokens: {
        USDC: {
            address: "0xcf04116d94818faba16c24c0e3d577f97babeaf7",
            decimal: 6,
        },
        BLIT: {
            address: "0x4aCAD9b5aA8BDfD43F2BF11fAc04Ed285068dbAE",
            decimal: 18,
        },
    },
    stakingTokens: {
        PE: {
            address: "0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
            priceAddress: "0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
            stakeAddress: "0xE881e99623787680DbbC0ecc65000a7D4E859514",
            decimal: 18,
            income: 1,
            type: "TOKEN",
            BuyLink:
                "https://app.pulsex.com/swap?outputCurrency=0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
        },
        PELP: {
            address: "0x1515d879556ae70a76154b505804f9a29afb4cfd",
            priceAddress: "0x1515d879556ae70a76154b505804f9a29afb4cfd",
            stakeAddress: "0x8B2151955f60Bf30c6bCd17ee9a5D30b04c48b2B",
            decimal: 18,
            income: 0.5,
            type: "LP",
            BuyLink:
                "https://v2-app.pulsex.com/add/0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197/0xF052BdA1AAf96deE984BaBc10e9f3424930f2E9c",
        },
    },
};
