import React, {useEffect, useState} from "react"
import {useTheme} from "../ThemeContext"
import {ConnectWallet, useAddress, useChainId, useSigner} from "@thirdweb-dev/react"

import {Link, NavLink, useLocation} from "react-router-dom"
import Container from "../Container"
import "./Header.scss"
import Logo_White from "./Logo_White.png"
import social1 from "../../images/social/social1.png"
import social2 from "../../images/social/social2.png"
import social3 from "../../images/social/social3.png"
import social1w from "../../images/social/social1-w.png"
import social2w from "../../images/social/social2-w.png"
import social3w from "../../images/social/social3-w.png"
import {Text16, Text18, Text20, Text24, Text36} from "../Text"
import MobileHeader from "./MobileHeader"
import DrawerRight from "../DrawerRight/DrawerRight"
import {IconButton} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import SwitchButton from "../SwitchButton"
import {toast} from "react-toastify"
import {
  getTokenTotalSupply
} from "../../utility/token";
import {
  getTotalInvested
} from "../../utility/stake";

const Header = (props) => {
  const location = useLocation()
  const {hash, pathname, search} = location
  const MenuList = [
    {
      title: "Dashboard",
      link: "/",
    },
    {
      title: "bLit",
      link: "/bLit",
    },
    {
      title: "bShare",
      link: "/bShare",
    },
    // {
    //   title: "Presale",
    //   link: "/presale",
    // },
    // {
    //   title: "Presale",
    //   link: "/",
    // },
    {
      title: "Swap",
      link: "/swap",
    },
    // {
    //   title: "Earn",
    //   link: "/Earn",
    // },
  ]

  const [openRightDrawer, setOpenRightDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawerRight = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setOpenRightDrawer({...openRightDrawer, [anchor]: open})
  }

  const chainId = useChainId()
  const [showErr, setShowErr] = useState(false)
  const address = useAddress();
  const signer = useSigner();

  const [totalSupply, setTotalSupply] = useState(0);
  const [tvl, setTVL] = useState(0);

  useEffect(() => {
    if (!showErr && chainId && chainId != 8453) {
      console.log("%c Line:76 🍺 chainId", "color:#f5ce50", chainId)
      setShowErr(true)
      toast.error(`Please connect to Base Network`)
    }
  }, [chainId])

  useEffect(() => {
    if (!address || !signer) return;
    const getData = async () => {
        let getTokenBal = await getTokenTotalSupply(
            "BLIT",
            signer,
        );
        setTotalSupply(parseFloat(getTokenBal).toFixed(3));
        let getTVL = await getTotalInvested(signer);
        setTVL(parseFloat(getTVL).toFixed(3));
    };
    getData();
}, [address, signer]);

  return (
    <>
      <div className="hero-sec py-8  ">
        {/* Header */}
        <Container className={"desktop-view"}>
          <header className=" mb-8 flex items-center justify-between">
            <div></div>
            <div className="header-sec bg-[#D7D7D7] rounded-3xl p-2 w-[855px] h-[60px] flex items-center justify-between mx-auto">
              <div className="menulist flex gap-4 items-center">
                {MenuList.map((db) => {
                  return (
                    <NavLink
                      className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                      }
                      to={db.link}
                    >
                      {db.title}
                    </NavLink>
                  );
                })}
              </div>
              <div className="flex items-center gap-12">
                <img src={Logo_White} />
                <div className="dark-social  flex items-center gap-6">
                  <img src={social1} />
                  <img src={social2} />
                  <a href="https://t.me/Baselitofficial" target="_blank">
                    <img src={social3} />
                  </a>
                </div>
                <div className="light-social flex items-center gap-6">
                  <img src={social1w} />
                  <img src={social2w} />
                  <a href="https://t.me/Baselitofficial" target="_blank">
                    <img src={social3w} />
                  </a>
                </div>
                <ConnectWallet className="connect-wallet" />
              </div>
            </div>

            <SwitchButton />
          </header>
        </Container>

        {/* Mobile Header  */}
        <MobileHeader
          MenuList={MenuList}
          pathname={pathname}
          SwitchButton={<SwitchButton />}
        />
        {/* <div className='flex justify-end'>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            // onClick={toggleDrawer(true)}
            onClick={toggleDrawerRight("right", true)}
            sx={{ mr: 2, color: 'white' }}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <DrawerRight id="category-menu-drawer" toggleDrawer={toggleDrawerRight}
          openRightDrawer={openRightDrawer}
          content={
            <>
              {MenuList.map((db) => {
                return (
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "active" : ""
                    }
                    to={db.link}
                  >
                    {db.title}
                  </NavLink>
                );
              })}
            </>
          } /> */}

        {/* Dashboard Card */}
        {pathname == "/" && (
          <Container>
            <div className="dashboard-card-header grid grid-cols-12 bg-[#d7d7d7dc] rounded-xl min-h-[307px] ">
              <div className="leftside rounded-l-xl sm:col-span-12 lg:col-span-8 p-10">
                <div className="flex flex-wrap justify-between items-center gap-5 w-full h-full">
                  <div>
                    <Text20>bLIT Supply</Text20>
                    <Text36>{totalSupply}</Text36>
                  </div>
                  <div className="flex gap-10 justify-end">
                    <div>
                      <div className="mb-4">
                        <Text16>TVL</Text16>
                        <Text20>${tvl}</Text20>
                      </div>
                      <div className="">
                        <Text16>Historical Yield</Text16>
                        <Text20>0 WETH</Text20>
                      </div>
                    </div>
                    <div>
                      <div className="mb-4">
                        <Text16>Treasury Balance</Text16>
                        <Text20>$ 0</Text20>
                      </div>
                      <div className="">
                        <Text16>Boardroom APR</Text16>
                        <Text20>0 %</Text20>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-12 lg:col-span-4 bg-[#6178eabb] p-10 rounded-r-xl text-center">
                <div>
                  <div className="mb-6">
                    <Text20>BaseLit Price</Text20>
                    <Text36>$1.00</Text36>
                  </div>
                  <div className="mb-1">
                    <Text18>$bLit Staked</Text18>
                    <Text20>{tvl}</Text20>
                  </div>
                  <div className="">
                    <Text20>Circulating Market Cap</Text20>
                    <Text24>${totalSupply - tvl}</Text24>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        )}
        {/* bLit */}
        {pathname.includes("/bLit") && (
          <Container className="text-white sm:h-28 lg:h-48">
            <h1 className="sm:text-[28px] lg:text-[96px] sm:leading-[60px] lg:leading-[100px] font-extrabold text-center">
              Stake $bLIT
            </h1>
            <h1 className="sm:text-lg lg:text-[36px] font-medium text-center sm:leading-5 lg:leading-9">
              Stake blit to earn staking reward in usdt.
            </h1>
          </Container>
        )}
        {pathname == "/coming-soon" && (
          <Container className="text-white sm:h-28 lg:h-48">
            <h1 className="sm:text-[28px] lg:text-[96px] sm:leading-[60px] lg:leading-[100px] font-extrabold text-center">
              Coming Soon
            </h1>
            <h1 className="sm:text-lg lg:text-[36px] font-medium text-center sm:leading-5 lg:leading-9 mt-2">
              Staking of blit is coming soon.
            </h1>
          </Container>
        )}
        {/* bShare */}
        {pathname == "/bShare" && (
          <Container className="text-white lg:h-48">
            <h1 className="sm:text-[28px] lg:text-[96px] sm:leading-[60px] lg:leading-[100px] font-extrabold text-center">
              Earn wETH
            </h1>
            <h1 className="sm:text-lg lg:text-[36px] font-medium text-center sm:leading-5 lg:leading-9">
              Deposit your bShare and start earning after 24 hours. Rewards are
              generated through fees and trading volume. ETH is distributed
              every 24hours.
            </h1>
          </Container>
        )}
        {/* presale */}
        {/* {(pathname == "/presale" || pathname == "/") && (
          <Container className="text-white h-48">
            <h1 className="sm:text-[28px] lg:text-[96px] sm:leading-[60px] lg:leading-[100px] font-extrabold text-center">
              Presale
            </h1>
          </Container>
        )} */}
        {pathname.includes("/swap") && (
          <Container className="text-white h-48">
            <h1 className="sm:text-[28px] lg:text-[96px] sm:leading-[60px] lg:leading-[100px] font-extrabold text-center">
              Swap
            </h1>
            {/* <h1 className="text-[36px] font-medium text-center leading-9">
              Deposit your bShare and start earning after 24 hours. Rewards are
              generated through fees and trading volume. ETH is distributed
              every 24hours.
            </h1> */}
          </Container>
        )}
        {/* Earn */}
        {pathname == "/Earn" && (
          <Container className="text-white sm:h-32 lg:h-48">
            <h1 className="sm:text-[28px] lg:text-[96px] sm:leading-[60px] lg:leading-[100px] font-extrabold text-center">
              Earn $bSHARE and $bLIT
            </h1>
            <h1 className="sm:text-lg lg:text-[36px] font-medium text-center sm:leading-5 lg:leading-9">
              Use vETH and sVEC in DeFi to explore additional reward
              opportunities while earning staking rewards.
            </h1>
          </Container>
        )}
      </div>
    </>
  );
}

export default Header
