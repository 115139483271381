import React, { useEffect, useState } from "react";
import bLit from "../../components/Header/Logo_White.png";
import usdc from "../../../src/images/icon/usdc.png";
import { Text16 } from "../../components/Text";
import { Card } from "../../components/shared/card";
import { GrTransaction } from "react-icons/gr";
import {
  getTokenAllowance,
  getTokenBalance,
  approveToken,
} from "../../utility/token";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import { toast } from "react-toastify";
import { config } from "../../config";
import { Balance, CopyAll } from "@mui/icons-material";
import { mintTokenFun } from "../../utility/swap";
import CopyToClipboard from "react-copy-to-clipboard";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import LaunchTime from "../launchTime";
import MyModal from "../../components/shared/MyModal";

const Swap = () => {

  let [isComing, setIsComing] = useState(true)
  function closeModal() {
    console.log("clicked")
  }

  const address = useAddress();
  const signer = useSigner();
  const { id } = useParams();
  const [swap, setSwap] = useState(true);

  const [contractBalance, setContractBalance] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [contractBalanceTotal, setContractBalanceTotal] = useState(0);

  const setAmountData = async (e) => {
    setAmount(e.target.value);
    setGetAmount(e.target.value * 840);
  };
  const [amount, setAmount] = useState(0);
  const [getAmount, setGetAmount] = useState(0);
  const [selectedToken, setSelectedToken] = useState("USDC");
  const [claimable, setClaimable] = useState(0);
  const [approvedBalance, setApprovedBalance] = useState(0);
  const [minAmount, setMinAmount] = useState(0.01);
  const [maxAmount, setMaxAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [epochTime, setEpochTime] = useState(0);

  useEffect(() => {
    setCurrentTime(parseInt((new Date().getTime())/1000))
    setEpochTime(parseInt(1711022102))
  },[])

  useEffect(() => {
    if (!address || !signer) return;
    const getData = async () => {
      setContractBalanceTotal(0);
      let getTokenBal = await getTokenBalance(
        address,
        swap ? "BLIT" : "USDC",
        signer
      );
      setContractBalanceTotal(parseFloat(getTokenBal).toFixed(3));
    };
    getData();
  }, [address, signer, swap, loading]);

  // useEffect(() => {
  //     if (!address || !signer) return;
  //     const getData = async () => {
  //         let data = await getMinAmount(config.saleAddress, signer);
  //         setMinAmount(data);
  //     };
  //     getData();
  // }, [address, signer, loading]);

  // useEffect(() => {
  //     if (!address || !signer) return;
  //     const getData = async () => {
  //         let data = await getMaxAmount(config.saleAddress, signer);
  //         setMaxAmount(data);
  //     };
  //     getData();
  // }, [address, signer, loading]);

  // useEffect(() => {
  //     if (!address || !signer) return;
  //     const getData = async () => {
  //         let getTokenBal = await addressBalance(config.saleAddress, signer);
  //         setContractBalance(parseFloat(getTokenBal));
  //     };
  //     getData();
  // }, [address, signer, selectedToken]);

  useEffect(() => {
    if (!address || !signer) return;
    const getData = async () => {
      setUserBalance(0);
      let getTokenBal = await getTokenBalance(
        address,
        swap ? "USDC" : "BLIT",
        signer
      );
      // let { data } = balance;
      // console.log("%c Line:149 🍿 data", "color:#2eafb0", data);
      // setWalletBalance(parseFloat(data?.displayValue));

      setUserBalance(parseFloat(getTokenBal).toFixed(3));
    };
    getData();
  }, [address, signer, swap, loading]);

  useEffect(() => {
    if (!address || !signer) return;
    const getData = async () => {
      let data = await getTokenAllowance(
        address,
        swap ? "USDC" : "BLIT",
        config.swapAddress,
        signer
      );
      setApprovedBalance(parseFloat(data));
    };
    getData();
  }, [address, signer, loading, swap]);

  const allowToken = async () => {
    setLoading(true);

    let data = await approveToken(
      address,
      swap ? "USDC" : "BLIT",
      config.swapAddress,
      signer
    );
    console.log("%c Line:131 🍻 data", "color:#465975", data);
    if (!data) {
      toast.error(`Error While Transaction`);
      setLoading(false);
      return;
    }
    toast.success(`Approved successfully`);
    setLoading(false);
  };

  const depositToken = async () => {
    setLoading(true);

    if (!amount || parseFloat(amount) <= 0) {
      toast.error(`Enter min amount`);
      setLoading(false);
      return;
    }
    // let max = parseFloat(maxAmount) - parseFloat(claimable / 1000000);

    // if (amount > max) {
    //     toast.error(`Enter Max amount ${max}`);
    //     setLoading(false);
    //     return;
    // }
    let data = await mintTokenFun(id, swap, config.swapAddress, signer, amount);
    if (!data) {
      toast.error(`Error While Transaction`);
      setLoading(false);
      return;
    }
    toast.success(`Transaction Complete`);
    setAmount(0);
    setLoading(false);
  };

  return (
    <Card className="relative top-6 py-8 w-[448px] max-w-[90%] mx-auto">
      <>
        <Card className={` p-3 px-4 mb-3 flex justify-between items-center`}>
          <input
            type="number"
            placeholder="Amount"
            className="w-1/2 outline-none text-2xl bg-transparent"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <div>
            <div className="flex items-center justify-end gap-1">
              <img
                src={swap ? usdc : bLit}
                alt=""
                className={`${swap ? "size-[20px]" : "size-[30px]"}`}
              />
              <Text16>{swap ? "USDC" : "bLit"}</Text16>
            </div>
            <div className="flex items-center justify-end gap-1 mt-1">
              <span className="text-[12px]">Balance: {userBalance}</span>
              <button
                onClick={(e) => {
                  setAmount(userBalance);
                }}
                className="bg-[#6175E8] rounded-xl w-[50px] py-[2px] text-[12px] text-white "
              >
                Max
              </button>
            </div>
          </div>
        </Card>
        <GrTransaction
          className="rounded-full w-full text-center cursor-pointer my-4 size-[22px] rotate-90 "
          onClick={() => setSwap(!swap)}
        />
        <Card className={` p-3 px-4 mb-3 flex justify-between items-center`}>
          <input
            type="number"
            placeholder="Amount"
            className="w-3/4 outline-none text-2xl bg-transparent"
            value={amount}
            disabled
            // onChange={e => setAmount(e.target.value)}
          />
          <div>
            <div className="flex items-center justify-end gap-1">
              <img
                src={!swap ? usdc : bLit}
                alt=""
                className={`${!swap ? "size-[20px]" : "size-[30px]"}`}
              />
              <Text16>{!swap ? "USDC" : "bLit"}</Text16>
            </div>
            <div className="flex items-center justify-end gap-1 mt-1">
              <span className="text-[12px]">
                Balance: {contractBalanceTotal}
              </span>
            </div>
          </div>
        </Card>
        <CopyToClipboard
          text={`${window.location.host}/#/swap/${address}`}
          onCopy={() => toast.success("Copied!")}
        >
          <span style={{ float: "inline-end" }}>
            Copy your referral link{" "}
            <IconButton > 
              {" "}
              <CopyAll style={{fill:"#fff"}} />{" "}
            </IconButton>
          </span>
        </CopyToClipboard>
        {loading ? (
          <button
            className="bg-[#6175E8] rounded-xl w-full py-[6px] text-[20px] text-white mt-8 mb-5"
            disabled
          >
            Loading
          </button>
        ) : approvedBalance > 0 ? (
          <button
            className="bg-[#6175E8] rounded-xl w-full py-[6px] text-[20px] text-white mt-8 mb-5"
            disabled={amount > 0 ? false : true}
            onClick={depositToken}
          >
            Swap
          </button>
        ) : (
          <button
            className="bg-[#6175E8] rounded-xl w-full py-[6px] text-[20px] text-white mt-8 mb-5"
            onClick={allowToken}
          >
            Approve
          </button>
        )}
      </>
      {
      currentTime < epochTime && 
      <MyModal
      isOpen={isComing}
      closeModal={closeModal}
      className={`w-[552px] max-w-full p-6 backdrop-blur-1`}>
      <LaunchTime />
      </MyModal>
    }
    </Card>
  );
};

export default Swap;
